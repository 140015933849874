<template>
  <b-container class="mt-5 mb-3">
    <h1>Sústredenia</h1>
    <p>
      Na tejto stránke nájdeš zoznam našich minulých aj budúcich sústredení.
    </p>

    <LoadingSpinner
      :is-loaded="eventsLoaded"
    >
      <b-row>
        <b-col
          v-for="event in events"
          :key="event.id"
          md="4"
          class="mb-4"
        >
          <EventSummary
            :event="event"
          />
        </b-col>
      </b-row>
    </LoadingSpinner>
  </b-container>
</template>

<script>
import { apiEvents, LoadingSpinner } from 'frontend-common'
import EventSummary from '@/components/EventSummary.vue'

export default {
  name: 'Events',
  components: {
    LoadingSpinner,
    EventSummary,
  },
  mixins: [
    apiEvents,
  ],
  props: {},
  data() {
    return {
      eventsLoaded: false,
      events: [],
    }
  },
  computed: {},
  watch: {
    '$root.isAuthenticated': 'loadEvents',
  },
  mounted() {
    this.loadEvents()
  },
  methods: {
    loadEvents() {
      this.apiEvents().
        then(response => this.events = response).
        finally(() => this.eventsLoaded = true)
    },
  },
}
</script>

<style scoped>

</style>
